<i18n>
{
  "de": {
    "descriptionAndStatusHeader": "Bezeichnung & Status"
  }
}
</i18n>

<template>
  <DetailListItem
    :title="$t('descriptionAndStatusHeader')"
    expandable
    class="c-description-and-status-summary-row"
    @expand="onModalOpen"
  >
    <div class="item-content">
      <span>{{ building.identifier }}</span>
      <span>{{ building.description }}</span>
    </div>
    <template #modal>
      <Modal v-if="isModalOpen" @close="onModalClose">
        <template #header>
          <h2>{{ $t('descriptionAndStatusHeader') }}</h2>
        </template>
        <EditBaseData :building="building" />
        <Divider class="divider" />
        <EditActive :building="building" />
        <Divider class="divider" />
        <EditStatus :building="building" />
      </Modal>
    </template>
  </DetailListItem>
</template>

<script>
import ModalMixin from '@/components/shared/ModalMixin.vue'

import Modal from '@/components/shared/Modal.vue'
import EditBaseData from '@/components/building/edit/EditBaseData.vue'
import DetailListItem from '@/components/shared/lists/DetailListItem.vue'
import Divider from '@/components/cui/data-display/Divider.vue'
import EditActive from '@/components/building/edit/EditActive.vue'
import EditStatus from '@/components/building/edit/EditStatus.vue'

export default {
  mixins: [
    //
    ModalMixin,
  ],

  components: {
    Modal,
    EditBaseData,
    DetailListItem,
    Divider,
    EditActive,
    EditStatus,
  },

  props: {
    building: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.c-description-and-status-summary-row {
  & .item-content {
    display: flex;
    flex-direction: row;
    gap: var(--spacing-s);

    & span:first-child {
      flex: none;
      text-transform: uppercase;
      color: var(--secondary-text-color);
    }

    & span:nth-child(2) {
      flex: 1 1;
      width: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  & .divider {
    margin: var(--spacing-l) 0;
  }
}
</style>
