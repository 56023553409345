<i18n>
{
  "de": {
    "remarksLabel": "Beschreibung",
    "yearLabel": "Jahr",
    "phaseLabel": "Status",
    "noMeasures": "Keine Massnahmen ausgewählt",
    "envelopeMeasureTitle": "Sanierung Gebäudehülle",
    "heatingMeasureTitle": "Heizungsersatz",
    "pvMeasureTitle": "Photovoltaik",
    "zoneMeasureTitle": "Flächen- und Nutzungsänderung",
    "energyGridMeasureTitle": "Stromkonfiguration",
    "addEnvelopeMeasure": "Gebäudehülle",
    "addHeatingMeasure": "Heizungsersatz",
    "addPvMeasure": "Photovoltaik",
    "addZonesMeasure": "Fläche & Nutzung",
    "addEnergyGridMeasure": "Stromkonfiguration",
    "removeMeasure": "Massnahme entfernen",
    "scenarioEditedInfoBox": "Wird nur im aktuell gewählten Szenario berücksichtigt",
    "plannedInfoBox": "Wird in allen Szenarien berücksichtigt",
    "completedInfoBox": "Wird in allen Szenarien berücksichtigt"
  }
}
</i18n>

<template>
  <EditContainer class="measure-edit" :bind-form-state="formState" :allow-edit="allowEdit" @container="onFormEvent">
    <!-- Remarks -->
    <FormRow v-if="isEditing" :label="$t('remarksLabel')">
      <TextInput
        v-if="getBuildingPermission('EDIT_MEASURES')"
        v-model="model.remarks"
        :edit="isEditing"
        :allow-empty="true"
      />
      <span v-else>{{ model.remarks || '-' }}</span>
    </FormRow>

    <!-- Year -->
    <FormRow :label="$t('yearLabel')">
      <NumericInput
        v-if="getBuildingPermission('EDIT_MEASURES')"
        v-model="model.year"
        noFormatting
        int
        :min="minYear"
        :max="maxYear"
        :edit="isEditing"
        class="year-input"
        @validation="onValidation($event, 'year')"
      />
      <span v-else>{{ model.year || '-' }}</span>
    </FormRow>

    <!-- Phase -->
    <FormRow :label="$t('phaseLabel')">
      <div v-if="isEditing">
        <input id="phase-scenario-edited" v-model="phase" type="radio" value="SCENARIO_EDITED" />
        <label for="phase-scenario-edited">
          <span>{{ $t('_measurePhases.SCENARIO_EDITED') }}</span>
          <InfoBox>
            <span>{{ $t('scenarioEditedInfoBox') }}</span>
            <template v-if="selectedScenario.description"
              >:
              <br />
              <span>{{ selectedScenario.description }}</span>
            </template>
          </InfoBox>
        </label>
        <br />
        <input id="phase-planned" v-model="phase" type="radio" value="PLANNED" />
        <label for="phase-planned">
          <span>{{ $t('_measurePhases.PLANNED') }}</span>
          <InfoBox>{{ $t('plannedInfoBox') }}</InfoBox>
        </label>
        <br />
        <input id="phase-completed" v-model="phase" type="radio" value="COMPLETED" />
        <label for="phase-completed">
          <span>{{ $t('_measurePhases.COMPLETED') }}</span>
          <InfoBox>{{ $t('completedInfoBox') }}</InfoBox>
        </label>
      </div>
      <div v-else class="measure-phase">
        <span v-if="measure.phase === 'SCENARIO'">{{ $t(`_measurePhases.SCENARIO`) }}</span>
        <span v-else>{{ $t(`_measurePhases.${model.phase}`) }}</span>
      </div>
    </FormRow>

    <!-- Add measure buttons -->
    <template v-if="isEditing && getBuildingPermission('EDIT_MEASURES')">
      <ButtonWrapper>
        <Button
          v-if="!model.actions.envelope && !hideEnvelopeMeasures && portfolio.enabled_features.ENVELOPE_MEASURES"
          icon="plus"
          :text="$t('addEnvelopeMeasure')"
          @click="addEnvelopeMeasure"
        />
        <Button
          v-if="!model.actions.heating && portfolio.enabled_features.HEATING_MEASURES"
          icon="plus"
          :text="$t('addHeatingMeasure')"
          @click="addHeatingMeasure"
        />
        <Button
          v-if="!model.actions.pv && portfolio.enabled_features.PV_MEASURES"
          icon="plus"
          :text="$t('addPvMeasure')"
          @click="addPvMeasure"
        />
        <Button
          v-if="!model.actions.zones && portfolio.enabled_features.ZONES_MEASURES"
          icon="plus"
          :text="$t('addZonesMeasure')"
          @click="addZonesMeasure"
        />
        <Button
          v-if="
            !model.actions.electricity &&
            'ENERGY_GRID_MEASURES' in portfolio.enabled_features &&
            portfolio.enabled_features.ENERGY_GRID_MEASURES
          "
          icon="plus"
          :text="$t('addEnergyGridMeasure')"
          @click="addEnergyGridMeasure"
        />
      </ButtonWrapper>
    </template>

    <div v-if="noSelectedMeasures" class="measure-box">
      {{ $t('noMeasures') }}
    </div>

    <!-- ENVELOPE -->
    <div v-if="model.actions.envelope !== null" class="measure-box">
      <div class="title-grid">
        <h2>{{ $t('envelopeMeasureTitle') }}</h2>
        <Button
          v-if="isEditing && getBuildingPermission('EDIT_MEASURES')"
          class="button--delete"
          icon="trash"
          :text="$t('removeMeasure')"
          @click="removeEnvelopeMeasure"
        />
      </div>
      <EditEnvelopeMeasure
        v-model="model.actions.envelope"
        :building="building"
        :portfolio="portfolio"
        :edit="isEditing && getBuildingPermission('EDIT_MEASURES')"
        @input="onEnvelopeChange"
        @validation="onValidation($event, 'envelope-measures')"
      />
    </div>

    <!-- HEATING -->
    <div v-if="model.actions.heating !== null" class="measure-box">
      <div class="title-grid">
        <h2>{{ $t('heatingMeasureTitle') }}</h2>
        <Button
          v-if="isEditing && getBuildingPermission('EDIT_MEASURES')"
          icon="trash"
          :text="$t('removeMeasure')"
          @click="removeHeatingMeasure"
        />
      </div>
      <EditHeatingMeasure
        v-model="model.actions.heating"
        :heaters-results="nextState && nextState.result && nextState.result.heaters"
        :portfolio="portfolio"
        :edit="isEditing && getBuildingPermission('EDIT_MEASURES')"
        @input="onHeatingChange"
        @validation="onValidation($event, 'heating-measures')"
      />
    </div>

    <!-- PV -->
    <div v-if="model.actions.pv !== null" class="measure-box">
      <div class="title-grid">
        <h2>{{ $t('pvMeasureTitle') }}</h2>
        <Button
          v-if="isEditing && getBuildingPermission('EDIT_MEASURES')"
          class="button--delete"
          icon="trash"
          :text="$t('removeMeasure')"
          @click="removePvMeasure"
        />
      </div>
      <EditPvMeasure
        v-model="model.actions.pv"
        :edit="isEditing && getBuildingPermission('EDIT_MEASURES')"
        :pv-results="nextState && nextState.result && nextState.result.pv"
        @input="onPvChange"
        @validation="onValidation($event, 'pv-measures')"
      />
    </div>

    <!-- ZONES -->
    <div v-if="model.actions.zones !== null" class="measure-box">
      <div class="title-grid">
        <h2>{{ $t('zoneMeasureTitle') }}</h2>
        <Button
          v-if="isEditing && getBuildingPermission('EDIT_MEASURES')"
          class="button--delete"
          icon="trash"
          :text="$t('removeMeasure')"
          @click="removeZonesMeasure"
        />
      </div>
      <EditZonesTable
        v-model="model.actions.zones.zones"
        :edit="isEditing && getBuildingPermission('EDIT_MEASURES')"
        :zones="model.actions.zones.zones"
        @input="onZonesChange"
        @validation="onValidation($event, 'zones-measures')"
      />
    </div>

    <!-- ENERGY_GRID -->
    <div v-if="model.actions.electricity !== null" class="measure-box">
      <div class="title-grid">
        <h2>{{ $t('energyGridMeasureTitle') }}</h2>
        <Button
          v-if="isEditing && getBuildingPermission('EDIT_MEASURES')"
          class="button--delete"
          icon="trash"
          :text="$t('removeMeasure')"
          @click="removeEnergyGridMeasure"
        />
      </div>
      <EditElectricityMeasure
        v-model="model.actions.electricity"
        :edit="isEditing && getBuildingPermission('EDIT_MEASURES')"
        :measure="measure"
        :building="building"
        :portfolio="portfolio"
        @input="onEnergyGridChange"
        @validation="onValidation($event, 'electricity-measures')"
      />
    </div>
  </EditContainer>
</template>

<script>
import { mapActions } from 'vuex'

import ApiFormMixin from '@/components/shared/forms/ApiFormMixin'

import EditContainer from '@/components/shared/forms/EditContainer.vue'
import FormRow from '@/components/shared/forms/FormRow.vue'
import TextInput from '@/components/shared/forms/TextInput.vue'
import NumericInput from '@/components/shared/forms/NumericInput.vue'
import EditZonesTable from '@/components/building/edit/EditZonesTable.vue'
import EditPvMeasure from '@/components/building/measures/edit/EditPvMeasure.vue'
import EditHeatingMeasure from '@/components/building/measures/edit/EditHeatingMeasure.vue'
import EditEnvelopeMeasure from '@/components/building/measures/edit/EditEnvelopeMeasure.vue'
import EditElectricityMeasure from '@/components/building/measures/edit/EditElectricityMeasure.vue'
import ButtonWrapper from '@/components/shared/ButtonWrapper.vue'
import InfoBox from '@/components/shared/InfoBox.vue'
import Button from '@/components/cui/inputs/Button.vue'

export default {
  mixins: [
    ApiFormMixin,
    /* ApiFormMixin:
        - Requires implementation of the following methods: resetModel, saveHandler, isValid (optional)
        - Provides: model, isValid, isEditing, formState, onFormEvent, onValidation,
                    getBuildingPermission, getPortfolioPermission
      */
  ],

  components: {
    EditContainer,
    EditZonesTable,
    EditPvMeasure,
    EditHeatingMeasure,
    EditEnvelopeMeasure,
    EditElectricityMeasure,
    TextInput,
    NumericInput,
    FormRow,
    ButtonWrapper,
    InfoBox,
    Button,
  },

  props: {
    selectedScenario: {
      type: Object,
      required: true,
    },
    building: {
      // create new measure for this building
      type: Object,
      required: true,
    },
    measure: {
      type: Object,
      required: false,
    },
    portfolio: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      phase: '',
    }
  },

  computed: {
    nextState() {
      return this.measure && this.measure.next_state
    },

    hideEnvelopeMeasures() {
      return (
        this.building.initial_state.envelope.without_heating || this.building.initial_state.envelope.manual_rh_demand
      )
    },

    noSelectedMeasures() {
      return (
        this.model.actions.pv === null &&
        this.model.actions.heating === null &&
        this.model.actions.envelope === null &&
        this.model.actions.zones === null &&
        this.model.actions.electricity === null
      )
    },

    allowEdit() {
      return this.getBuildingPermission('EDIT_MEASURES')
    },

    isValid() {
      for (let i = 0; i < this.invalidFields.length; i++) {
        const field = this.invalidFields[i]
        if (field === 'pv-measures') {
          if (this.model.actions.pv !== null) {
            return false
          }
        } else if (field === 'heating-measures') {
          if (this.model.actions.heating !== null) {
            return false
          }
        } else if (field === 'envelope-measures') {
          if (this.model.actions.envelope !== null) {
            return false
          }
        } else if (field === 'zones-measures') {
          if (this.model.actions.zones !== null) {
            return false
          }
        } else if (field === 'electricity-measures') {
          if (this.model.actions.electricity !== null) {
            return false
          }
        } else {
          return false
        }
      }
      // At least one measure must be selected
      if (this.noSelectedMeasures) {
        return false
      }
      if (this.model.actions.heating !== null && (this.totalRhShare !== 100 || this.totalDhwShare !== 100)) {
        return false
      }
      // If envelope renovation is selected, at least one component must be renovated
      if (
        this.model.actions.envelope !== null &&
        !(
          this.model.actions.envelope.walls !== null ||
          this.model.actions.envelope.windows !== null ||
          this.model.actions.envelope.roof !== null ||
          this.model.actions.envelope.basement !== null
        )
      ) {
        return false
      }
      // Heating type must be selected
      if (this.model.actions.heating !== null && this.model.actions.heating.heaters.length === 0) {
        return false
      }
      // If PV actions are defined but no anlage
      if (
        this.model.actions.pv !== null &&
        this.model.actions.pv.method === 'MANUAL' &&
        (this.model.actions.pv.sections == null || this.model.actions.pv.sections.length === 0)
      ) {
        return false
      }
      if (
        this.model.actions.electricity !== null &&
        this.model.actions.electricity.grid_id === -1 &&
        this.model.actions.electricity.pv_id === -1 &&
        this.model.actions.electricity.zev === null
      ) {
        return false
      }
      // At least one zone must be defined
      if (this.model.actions.zones !== null && this.model.actions.zones.zones.length === 0) {
        return false
      }
      // Year must be between min and max year
      return this.minYear <= this.model.year && this.model.year <= this.maxYear
    },

    minYear() {
      const BUILDING_SCOPED_MEASURE_TYPE = ['COMPLETED', 'PLANNED']
      if (this.model.phase === 'SCENARIO_EDITED') {
        return (
          this.building.measures
            .filter((m) => m.id !== this.model.id && BUILDING_SCOPED_MEASURE_TYPE.includes(m.phase))
            .reduce((a, b) => Math.max(a, b.year), 1899) + 1
        )
      } else if (this.model.phase === 'PLANNED') {
        return (
          this.building.measures
            .filter((m) => m.id !== this.model.id && m.phase === 'COMPLETED')
            .reduce((a, b) => Math.max(a, b.year), 1899) + 1
        )
      } else {
        return 1900
      }
    },

    maxYear() {
      const BUILDING_PLANNED_MEASURE_TYPE = ['SCENARIO_EDITED', 'PLANNED']
      if (this.model.phase === 'COMPLETED') {
        return (
          this.building.measures
            .filter((m) => m.id !== this.model.id && BUILDING_PLANNED_MEASURE_TYPE.includes(m.phase))
            .reduce((a, b) => Math.min(a, b.year), 2100 + 1) - 1
        )
      } else if (this.model.phase === 'PLANNED') {
        return (
          this.building.measures
            .filter((m) => m.id !== this.model.id && m.phase === 'SCENARIO_EDITED')
            .reduce((a, b) => Math.min(a, b.year), 2100 + 1) - 1
        )
      } else {
        return 2100
      }
    },

    totalRhShare() {
      return this.model.actions.heating && this.model.actions.heating.heaters.reduce((pv, cv) => pv + cv.rh_share, 0)
    },

    totalDhwShare() {
      return this.model.actions.heating && this.model.actions.heating.heaters.reduce((pv, cv) => pv + cv.dhw_share, 0)
    },
  },

  watch: {
    building() {
      this.resetModel()
    },

    phase() {
      this.model.phase = this.phase
      this.onValidation(this.minYear <= this.model.year && this.model.year <= this.maxYear, 'year')
    },

    isEditing() {
      this.$emit('edit', this.isEditing)
    },
  },

  created() {
    if (!this.model.id) {
      // Start editing immediately when adding a new measure
      this.onFormEvent('edit')
    }
    this.resetModel()
  },

  methods: {
    ...mapActions({
      addMeasure: 'building/addMeasure',
      updateMeasure: 'building/updateMeasure',
    }),

    resetModel() {
      if (this.measure && this.measure.phase === 'SCENARIO') {
        // Create planned measure when editing a scenario measure
        this.model = {
          building_id: this.measure.building_id,
          scenario_id: this.selectedScenario.id,
          year: this.measure.year,
          phase: 'SCENARIO_EDITED',
          actions: JSON.parse(JSON.stringify(this.measure.actions)),
        }
      } else if (this.measure) {
        this.model = {
          id: this.measure.id,
          building_id: this.measure.building_id,
          scenario_id: this.selectedScenario.id,
          year: this.measure.year,
          phase: this.measure.phase,
          remarks: this.measure.remarks,
          actions: JSON.parse(JSON.stringify(this.measure.actions)),
        }
      } else {
        this.model = {
          building_id: this.building.id,
          scenario_id: this.selectedScenario.id,
          year: new Date().getFullYear(),
          phase: 'SCENARIO_EDITED',
          remarks: null,
          actions: {
            envelope: null,
            pv: null,
            heating: null,
            zones: null,
            electricity: null,
          },
        }
      }
      this.phase = this.model.phase
    },

    async saveHandler(model) {
      if (model.actions.pv !== null) {
        if (model.actions.pv.method === 'MANUAL') {
          delete model.actions.pv.dimensioning
        } else {
          delete model.actions.pv.sections
        }
      }
      if (model.actions.electricity !== null) {
        model.actions.electricity.grid_id =
          model.actions.electricity.grid_id === -1 ? null : model.actions.electricity.grid_id
        model.actions.electricity.pv_id =
          model.actions.electricity.pv_id === -1 ? null : model.actions.electricity.pv_id
      }
      // Remove basement if not exists
      if (model.actions.envelope !== null && !this.building.initial_state.envelope.basement_exists) {
        delete model.actions.envelope.basement
      }
      if (['PLANNED', 'COMPLETED'].includes(model.phase)) {
        model.scenario_id = null
      }
      if (this.measure && this.measure.phase === 'SCENARIO') {
        // Create new measure based on scenario measure
        await this.addMeasure(model)
        this.$emit('created')
      } else if (this.measure) {
        // Update existing measure
        await this.updateMeasure(model)
        this.$emit('created')
      } else {
        // Create new measure
        await this.addMeasure(model)
        this.$emit('created')
      }
    },

    onZonesChange(zones) {
      this.model.actions.zones.zones = zones
    },

    addZonesMeasure() {
      this.model.actions.zones = JSON.parse(JSON.stringify(this.building.initial_state.zones))
    },

    removeZonesMeasure() {
      this.model.actions.zones = null
    },

    onEnvelopeChange(envelope) {
      this.model.actions.envelope = envelope
    },

    addEnvelopeMeasure() {
      this.model.actions.envelope = {
        walls: { standard: 'MuKEn_2014_RENOVATION', costs: null },
        windows: { standard: 'MuKEn_2014', costs: null },
        roof: { standard: 'MuKEn_2014_RENOVATION', costs: null },
        basement: this.building.initial_state.envelope.basement_exists
          ? { standard: 'MuKEn_2014_RENOVATION', costs: null }
          : null,
      }
    },

    removeEnvelopeMeasure() {
      this.model.actions.envelope = null
    },

    onPvChange(pv) {
      this.model.actions.pv = pv
    },

    addPvMeasure() {
      this.model.actions.pv = {
        method: 'AUTO',
        dimensioning: null,
        sections: null,
        costs: null,
        subsidies: null,
      }
    },

    removePvMeasure() {
      this.model.actions.pv = null
    },

    onHeatingChange(heating) {
      this.model.actions.heating = heating
    },

    addHeatingMeasure() {
      const unknownHeatingType = this.portfolio.heating_types.find((ht) => ht.name === 'UNKNOWN')
      this.model.actions.heating = {
        heaters: [
          {
            heating_type_id: unknownHeatingType ? 'UNKNOWN' : null,
            rh_share: 100,
            dhw_share: 100,
          },
        ],
        costs: null,
      }
    },

    removeHeatingMeasure() {
      this.model.actions.heating = null
    },

    onEnergyGridChange(electricity) {
      this.model.actions.electricity = electricity
    },

    addEnergyGridMeasure() {
      this.model.actions.electricity = {
        grid_id: -1,
        pv_id: -1,
        zev: null,
        costs: null,
      }
    },

    removeEnergyGridMeasure() {
      this.model.actions.electricity = null
    },
  },
}
</script>

<style lang="scss" scoped>
.measure-box {
  margin-top: var(--spacing-s);
  padding: var(--spacing-m);
  background-color: var(--background-color);
  border: var(--box-border);
  border-radius: var(--box-radius);
}
.title-grid {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-s);
}

.year-input {
  width: 100px;
}
.measure-phase {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);

  & img {
    vertical-align: bottom;
    margin-right: var(--spacing-s);
    opacity: 0.7;
  }
}
</style>
