import { genericLoadWithPortfolioAndQuery, genericRefreshWithPortfolioAndQuery } from '@/store/storeUtil'

const state = {
  loading: true,
  loaded: false,
  buildingsForList: [],
  // Context
  latestRequestId: null,
  portfolioId: null,
  query: '{}',
}

// Getters
const getters = {
  getBuildingsForList(state) {
    return () => {
      return state.buildingsForList
    }
  },

  getBuildingsForListLoading(state) {
    return () => {
      return state.loading
    }
  },

  getBuildingsForListLoaded(state) {
    return () => {
      return state.loaded
    }
  },
}

// Actions
const actions = {
  async load({ commit, rootState, state }, { portfolioId }) {
    await genericLoadWithPortfolioAndQuery({
      commit,
      state,
      rootState,
      portfolioId,
      urlFct: (portfolioId, query) => `/portfolios/${portfolioId}/buildings-for-list?query=${query}`,
    })
  },

  async refresh({ commit, rootState, state }) {
    await genericRefreshWithPortfolioAndQuery({
      commit,
      state,
      rootState,
      urlFct: (portfolioId, query) => `/portfolios/${portfolioId}/buildings-for-list?query=${query}`,
    })
  },
}

// Mutations
const mutations = {
  setContext(state, { portfolioId, query, requestId }) {
    state.portfolioId = portfolioId
    state.query = query
    state.latestRequestId = requestId
  },

  reset(state) {
    state.buildingsForList = []
  },

  loading(state) {
    state.loaded = false
    state.loading = true
  },

  loaded(state, { data }) {
    state.buildingsForList = data
    state.loaded = true
    state.loading = false
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
