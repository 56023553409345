<template>
  <div class="c-overview-section">
    <div>
      <!-- Group: Status and location -->
      <StatusAndLocationOverview :building="building" :portfolio="portfolio" />

      <!-- Group: Feasibility and potential analyses -->
      <FeasibilityAndPotentialOverview
        v-if="portfolio && portfolio.enabled_features.HEATING_FEASIBILITY"
        :building="building"
      />

      <!-- Group: Categories -->
      <TagGroupOverview :portfolio="portfolio" :building="building" :manual-tags="manualTags" />
    </div>

    <div>
      <!-- Card: Energy -->
      <EnergyKpiOverview
        v-if="building && building.kpi && portfolio && portfolio.enabled_features.KPI"
        :portfolio="portfolio"
        :building="building"
        :chart-data="filteredChartData"
      />
      <!-- Card: Emissions -->
      <EmissionsKpiOverview
        v-if="building && building.kpi && portfolio && portfolio.enabled_features.KPI"
        :portfolio="portfolio"
        :building="building"
        :chart-data="filteredChartData"
      />
      <!-- Card: PV -->
      <PvKpiOverview
        v-if="building && building.kpi && portfolio && portfolio.enabled_features.KPI"
        :portfolio="portfolio"
        :building="building"
        :chart-data="filteredChartData"
      />
    </div>
  </div>
</template>

<script>
import StatusAndLocationOverview from '@/components/building/overview/StatusAndLocationOverview.vue'
import FeasibilityAndPotentialOverview from '@/components/building/overview/FeasibilityAndPotentialOverview.vue'
import TagGroupOverview from '@/components/building/overview/TagGroupOverview.vue'
import EmissionsKpiOverview from '@/components/building/kpi/EmissionsKpiOverview.vue'
import EnergyKpiOverview from '@/components/building/kpi/EnergyKpiOverview.vue'
import PvKpiOverview from '@/components/building/kpi/PvKpiOverview.vue'

export default {
  components: {
    TagGroupOverview,
    StatusAndLocationOverview,
    FeasibilityAndPotentialOverview,
    EmissionsKpiOverview,
    EnergyKpiOverview,
    PvKpiOverview,
  },

  props: {
    building: {
      type: Object,
      required: true,
    },
    portfolio: {
      type: Object,
      required: true,
    },
    chartData: {
      type: Array,
      required: true,
    },
  },

  computed: {
    manualTags() {
      return this.portfolio.tag_groups.filter((t) => !t.auto)
    },

    filteredChartData() {
      return this.chartData.filter((building) => building.id === this.building.id)
    },
  },
}
</script>

<style lang="scss" scoped>
.c-overview-section {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: var(--spacing-m);

  @media screen and (max-width: 1400px) {
    flex-direction: column;
  }

  @media screen and (max-width: 1280px) {
    flex-direction: row;
  }

  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }

  & > div {
    flex: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--spacing-m);
    width: calc(50% - var(--spacing-m) / 2);

    @media screen and (max-width: 2600px) {
      flex-direction: column;
    }

    @media screen and (max-width: 1400px) {
      width: 100%;
    }

    @media screen and (max-width: 1280px) {
      width: calc(50% - var(--spacing-m) / 2);
    }

    @media screen and (max-width: 1000px) {
      width: 100%;
    }

    & > * {
      flex: none;
      width: calc(50% - var(--spacing-m) / 2);

      @media screen and (max-width: 2600px) {
        width: 100%;
      }
    }
  }
}
</style>
